export * from 'SourceUtil/Url/Url';

/**
 * Update query params without adding new param to url
 * @param {String} name
 * @param {String} value
 * @namespace ZnetPwa/Util/Url/updateQueryParamWithoutNewQuery
*/
export const updateQueryParamWithoutNewQuery = (name, value, history, location) => {
    const { search, pathname } = location;
    const params = new URLSearchParams(search);
    params.set(name, value);
    history.replace(decodeURIComponent(`${ pathname }`));
};

/**
 * Used to only encode &, %, # in GraphQL query
 * @namespace ZnetPwa/Util/Url/customEncodeURIComponent
*/
export const customEncodeURIComponent = (str) => str.replace(/[&%#+]/g,
    // eslint-disable-next-line no-magic-numbers
    (char) => `%${ char.charCodeAt(0).toString(16)}`).replace(/%20/g, '+');
