import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { AttributeType } from 'SourceType/ProductList';
import { isMobile } from 'SourceUtil/Mobile';
import { toggleScroll } from 'Util/Browser';

import ProductSwatchPopup from './ProductSwatchPopup.component';
import { PDP_PLP } from './ProductSwatchPopup.config';

/** @namespace ZnetPwa/Component/ProductSwatchPopup/Container/ProductSwatchPopupContainer */
export class ProductSwatchPopupContainer extends PureComponent {
    static propTypes = {
        isPDP: PropTypes.bool,
        activeSku: PropTypes.string,
        activeColor: PropTypes.string,
        products: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            sku: PropTypes.string,
            url: PropTypes.string,
            swatchImageUrl: PropTypes.string,
            price: PropTypes.string,
            priceLabel: PropTypes.string,
            discontinuedLabel: PropTypes.string
        })).isRequired,
        isConfigurableSwatchPopUpPLP: PropTypes.bool,
        isOpen: PropTypes.bool.isRequired,
        onPopUpOutsideClick: PropTypes.func.isRequired,
        handleOptionClick: PropTypes.func,
        configurableColor: AttributeType,
        getLink: PropTypes.func.isRequired,
        popUpType: PropTypes.string,
        getIsConfigurableAttributeAvailable: PropTypes.func
    };

    static defaultProps = {
        isPDP: false,
        isConfigurableSwatchPopUpPLP: false,
        handleOptionClick: () => null,
        getIsConfigurableAttributeAvailable: () => null,
        configurableColor: {},
        activeSku: '',
        activeColor: '',
        popUpType: PDP_PLP
    };

    containerFunctions = {
        onPopUpOutsideClick: this.onPopUpOutsideClick.bind(this),
        handleOptionClick: this.handleOptionClick.bind(this)
    };

    componentDidUpdate() {
        const {
            isOpen
        } = this.props;

        if (isMobile.any() && isOpen) {
            toggleScroll(false);
        }
    }

    componentWillUnmount() {
        if (isMobile.any()) {
            toggleScroll(true);
        }
    }

    containerProps() {
        const {
            activeSku,
            activeColor,
            products,
            isConfigurableSwatchPopUpPLP,
            isOpen,
            isPDP,
            configurableColor,
            getLink,
            popUpType,
            getIsConfigurableAttributeAvailable
        } = this.props;

        return {
            activeSku,
            activeColor,
            products: this._reorderProducts(products),
            isConfigurableSwatchPopUpPLP,
            isOpen,
            isPDP,
            configurableColor,
            getLink,
            popUpType,
            getIsConfigurableAttributeAvailable
        };
    }

    onPopUpOutsideClick() {
        const { onPopUpOutsideClick } = this.props;

        toggleScroll(true);
        onPopUpOutsideClick();
    }

    handleOptionClick(e, attributeObject, isConfigurableSwatchPopUpPLP, isActive) {
        const { handleOptionClick, onPopUpOutsideClick } = this.props;

        if (!isConfigurableSwatchPopUpPLP) {
            e.preventDefault();
            if (isActive) {
                return;
            }
            handleOptionClick(attributeObject);
            onPopUpOutsideClick();
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }

    _reorderProducts(products) {
        if (products.length) {
            const modifiedProducts = Array.from(products);

            modifiedProducts?.forEach((product, id) => {
                let sku_hash = '';

                Array.from(product.sku).forEach((letter) => {
                    const ASCII = letter.charCodeAt(0);
                    sku_hash += ASCII;
                });
                modifiedProducts[id].sku_hash = sku_hash;
            });

            const sorted = modifiedProducts?.sort((a, b) => a?.sku_hash?.localeCompare(b.sku_hash));

            return sorted;
        }

        return products;
    }

    render() {
        return (
            <ProductSwatchPopup
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default ProductSwatchPopupContainer;
