/* eslint-disable max-lines */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { CgClose } from 'react-icons/cg';

import ClickOutside from 'Component/ClickOutside';
import ImageWebp from 'Component/ImageWebp';
import Link from 'Component/Link';
import { AttributeType } from 'SourceType/ProductList';
import { isCrawler } from 'SourceUtil/Browser';
import { isMobile } from 'SourceUtil/Mobile';

import { CONFIGURABLE, PDP_PLP, THREE } from './ProductSwatchPopup.config';

import './ProductSwatchPopup.style';

/** @namespace ZnetPwa/Component/ProductSwatchPopup/Component/ProductSwatchPopupComponent */
export class ProductSwatchPopupComponent extends PureComponent {
    static propTypes = {
        activeColor: PropTypes.string.isRequired,
        activeSku: PropTypes.string.isRequired,
        isOpen: PropTypes.bool.isRequired,
        isPDP: PropTypes.bool.isRequired,
        isConfigurableSwatchPopUpPLP: PropTypes.bool.isRequired,
        onPopUpOutsideClick: PropTypes.func.isRequired,
        products: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            sku: PropTypes.string,
            url: PropTypes.string,
            swatchImageUrl: PropTypes.string,
            price: PropTypes.string,
            priceLabel: PropTypes.string
        })).isRequired,
        handleOptionClick: PropTypes.func.isRequired,
        configurableColor: AttributeType.isRequired,
        getLink: PropTypes.func.isRequired,
        popUpType: PropTypes.string.isRequired,
        getIsConfigurableAttributeAvailable: PropTypes.func.isRequired
    };

    swatchMap = {
        [PDP_PLP]: {
            renderPreview: this.renderProductPreview.bind(this)
        },
        [CONFIGURABLE]: {
            renderPreview: this.renderConfigurableProductPreview.bind(this)
        }
    };

    renderPriceOrLabel(price, priceLabel) {
        const { popUpType } = this.props;

        if (popUpType === CONFIGURABLE) {
            return null;
        }

        if (priceLabel === '') {
            const displayedPrice = price?.toString().includes('$') ? price : `$${ price }`;
            return (
                <div block="ProductSwatchPopup" elem="SwatchItemPrice">
                    { displayedPrice }
                </div>
            );
        }

        return (
            <div block="ProductSwatchPopup" elem="SwatchItemPriceLabel">
                { priceLabel }
            </div>
        );
    }

    renderSwatch = (item) => {
        const {
            swatchImageUrl,
            url,
            name,
            priceLabel,
            price,
            sku
        } = item;
        const { activeSku, isPDP } = this.props;
        const parts = name && name.split('-');
        const label = parts && parts.length > 1 ? parts[parts.length - 1] : name;
        const modifiedURL = url && !isPDP ? new URL(url).pathname : url;
        const alt = `${ sku } - ${ name }`;

        return (
            <Link to={ modifiedURL }>
                <div block="ProductSwatchPopup" elem="SwatchItemWrapper" mods={ { isActive: activeSku === sku } }>
                    <ImageWebp
                      mix={ {
                          block: 'ProductSwatchPopup',
                          elem: 'SwatchItemImage'
                      } }
                      ratio="custom"
                      src={ swatchImageUrl }
                      alt={ alt }
                      style={ { objectFit: 'cover' } }
                    />
                    <div block="ProductSwatchPopup" elem="SwatchItemInformation">
                        <span block="ProductSwatchPopup" elem="SwatchItemLabel">
                            { label }
                        </span>
                        { this.renderPriceOrLabel(price, priceLabel) }
                    </div>
                </div>
            </Link>
        );
    };

    renderConfigurableSwatch = (item) => {
        const {
            sku,
            name,
            attribute = '',
            swatchImageUrl,
            priceLabel,
            price,
            url
        } = item;
        const alt = `${ sku } - ${ name }`;

        const {
            activeColor,
            handleOptionClick,
            configurableColor: {
                attribute_code,
                attribute_options
            } = {},
            getLink,
            isConfigurableSwatchPopUpPLP,
            getIsConfigurableAttributeAvailable
        } = this.props;
        let isActive = false;
        let isDisabled = false;
        let attributeObject = {};
        let link = `/${url.split('/').slice(THREE).join('/')}`;
        let label = attribute;

        if (!isConfigurableSwatchPopUpPLP) {
            const swatch = Object.values(attribute_options)?.find((option) => option.label === name);

            attributeObject = {
                attribute_code,
                attribute_value: swatch?.value,
                attribute_options
            };
            isDisabled = !getIsConfigurableAttributeAvailable(attributeObject);
            isActive = activeColor.replace(/\+/g, ' ') === name;
            link = getLink(attributeObject);
            label = name;
        }

        return (
            <Link
              block="ProductSwatchPopup"
              elem="LinkWrapper"
              to={ link }
              // eslint-disable-next-line react/jsx-no-bind
              onClick={ (e) => handleOptionClick(e, attributeObject, isConfigurableSwatchPopUpPLP, isActive) }
              mods={ { isDisabled } }
            >
                <div block="ProductSwatchPopup" elem="SwatchItemWrapper" mods={ { isActive, isDisabled } }>
                    <ImageWebp
                      mix={ {
                          block: 'ProductSwatchPopup',
                          elem: 'SwatchItemImage',
                          mods: { isHidden: !!isConfigurableSwatchPopUpPLP }
                      } }
                      alt={ alt }
                      ratio="custom"
                      src={ swatchImageUrl }
                      style={ { objectFit: 'cover' } }
                    />
                    <div block="ProductSwatchPopup" elem="SwatchItemInformation">
                        <span block="ProductSwatchPopup" elem="SwatchItemLabel">
                            { label }
                        </span>
                        { this.renderPriceOrLabel(price, priceLabel) }
                    </div>
                </div>
            </Link>
        );
    };

    renderConfigurableProductPreview() {
        const {
            products,
            onPopUpOutsideClick,
            isConfigurableSwatchPopUpPLP,
            activeSku
        } = this.props;
        const htmlFor = `SwatchPopupTriggerCheckbox${ activeSku }`;

        return (
            <div block="ProductSwatchPopup" elem="SwatchListWrapper">
                <div block="ProductSwatchPopup" elem="HeaderWrapper">
                    <div block="ProductSwatchPopup" elem="NumberOfSwatches">
                        { __(`${products.length} available ${ isConfigurableSwatchPopUpPLP ? 'sizes' : 'color' } `) }
                    </div>
                    { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
                    <label
                      block="ProductActions"
                      elem="SwatchPopupTriggerLabel"
                      htmlFor={ htmlFor }
                    >
                    <CgClose
                      size="20px"
                      onClick={ onPopUpOutsideClick }
                      class="ProductSwatchPopup-Close"
                    />
                    </label>
                </div>
                { products.map((product) => this.renderConfigurableSwatch(product)) }
            </div>
        );
    }

    renderProductPreview() {
        const { products, onPopUpOutsideClick, activeSku } = this.props;

        const htmlFor = `SwatchPopupTriggerCheckbox${ activeSku }`;
        const crossSize = isMobile.any() ? '30px' : '20px';

        return (
            <div block="ProductSwatchPopup" elem="SwatchListWrapper">
                <div block="ProductSwatchPopup" elem="HeaderWrapper">
                    <div block="ProductSwatchPopup" elem="NumberOfSwatches">
                        { __(`${products.length} available colors`) }
                    </div>
                    { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
                    <label
                      block="ProductActions"
                      elem="SwatchPopupTriggerLabel"
                      htmlFor={ htmlFor }
                    >
                    <CgClose
                      size={ crossSize }
                      onClick={ onPopUpOutsideClick }
                      class="ProductSwatchPopup-Close"
                    />
                    </label>
                </div>
                <div block="ProductSwatchPopup" elem="Content">
                    { products.map(this.renderSwatch) }
                </div>
            </div>
        );
    }

    render() {
        const {
            activeSku,
            isOpen,
            isPDP,
            popUpType,
            onPopUpOutsideClick
        } = this.props;
        const {
            renderPreview
        } = this.swatchMap[popUpType];
        const id = `SwatchPopupTriggerCheckbox${ activeSku }`;

        if (isPDP) {
            return (
                <>
                { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */ }
                <div
                  block="ProductSwatchPopup"
                  elem="EmptySpace"
                  onClick={ onPopUpOutsideClick }
                  onKeyDown={ onPopUpOutsideClick }
                  role="button"
                  tabIndex={ -1 }
                />
                <input
                  block="ProductActions"
                  elem="SwatchPopupTriggerLabel"
                  type="checkbox"
                  id="SwatchPopupTriggerCheckbox"
                />
                <div
                  block="ProductSwatchPopup"
                  elem="Popup"
                  mods={ { isOpen, isPDP, isCrawler: isCrawler() } }
                >
                    { renderPreview() }
                </div>
                </>
            );
        }

        if (!isPDP && isOpen) {
            return (
                <ClickOutside onClick={ onPopUpOutsideClick }>
                    { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */ }
                    <div
                      block="ProductSwatchPopup"
                      elem="EmptySpace"
                      onClick={ onPopUpOutsideClick }
                      onKeyDown={ onPopUpOutsideClick }
                      role="button"
                      tabIndex={ -1 }
                    />
                    <input
                      block="ProductSwatchPopup"
                      elem="SwatchPopupTriggerCheckbox"
                      type="checkbox"
                      id={ id }
                    />
                    <div
                      block="ProductSwatchPopup"
                      elem="Popup"
                      mods={ { isOpen, isPDP, isCrawler: isCrawler() } }
                    >
                        { renderPreview() }
                    </div>
                </ClickOutside>
            );
        }

        return (
            <div>
                { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */ }
                <div
                  block="ProductSwatchPopup"
                  elem="EmptySpace"
                  onClick={ onPopUpOutsideClick }
                  onKeyDown={ onPopUpOutsideClick }
                  role="button"
                  tabIndex={ -1 }
                />
                <input
                  block="ProductSwatchPopup"
                  elem="SwatchPopupTriggerCheckbox"
                  type="checkbox"
                  id={ id }
                />
                <div
                  block="ProductSwatchPopup"
                  elem="Popup"
                  mods={ { isOpen, isPDP, isCrawler: isCrawler() } }
                >
                    { renderPreview() }
                </div>
            </div>
        );
    }
}

export default ProductSwatchPopupComponent;
