export * from 'SourceRoute/ProductPage/ProductPage.config';

export const NUMBER_OF_CHARACTERS_TO_SHOW = 250;
export const DESCRIPTION = 'description';
export const REVIEWS = 'reviews';
export const SHIPPING_INFO = 'shipping';
export const MAINTENANCE = 'maintenance';
export const INSTALLATION_INFO = 'installation';
export const WARRANTY = 'warranty';
export const ACCESSORIES = 'accessories';
export const INSTALL_LOCATION = 'install_location';
export const INSTALL_LOCATION_GROUP = 'installation-info';
export const WARRANTY_INFO = 'warranty_info';
export const WARRANTY_INFO_GROUP = 'warranty-info';
export const MAX_ACCESSORIES_COUNT = 100;
export const CROSS_SELL = 'crosssell';
export const TITLE_SUFFIX = 'from Znet Flooring';
export const ACCESSORIES_ATTRIBUTE_SET_NAME = 'Accessories';
export const TWO_THOUSAND = 2000;
export const DISCONTINUED_PRODUCT_LABELS = [
    'Discontinued - No replacement',
    'Discontinued - Replacement compatible',
    'Discontinued - Replacement not compatible'
];
export const VIRTUAL = 'virtual';
export const CHECKOUT_PATHNAME = '/checkout';
export const CART_PATHNAME = '/cart';
export const PREMIUM_CUSTOMER = 'Premium Customer';
